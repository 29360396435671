/* purgecss start ignore */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
/* purgecss end ignore */
@import 'tailwindcss/utilities';

/* purgecss start ignore */
html,
body {
  font-family: 'Roboto', sans-serif;
  @apply text-gray-700;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #546dc1;
  position: fixed;
  z-index: 1060;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #546dc1, 0 0 5px #546dc1;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #546dc1;
  border-left-color: #546dc1;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.w-kpi {
  min-width: 12rem;
  width: 12rem;
}

.h-kpi {
  height: 16rem;
  min-height: 16rem;
}

/* V: Fondo  */
.pg-success::-webkit-progress-bar {
  @apply bg-green-500 rounded-full;
}

.pg-success::-moz-progress-bar {
  @apply bg-green-500 rounded-full;
}

/* V: Barra */
.pg-success::-webkit-progress-value {
  @apply bg-green-700 rounded-full;
}

.pg-success::-moz-progress-value {
  @apply bg-green-700 rounded-full;
}

/* A: Fondo  */
.pg-warning::-webkit-progress-bar {
  @apply bg-yellow-400 rounded-full;
}

.pg-warning::-moz-progress-bar {
  @apply bg-yellow-400 rounded-full;
}

/* A: Barra */
.pg-warning::-webkit-progress-value {
  @apply bg-yellow-600 rounded-full;
}

.pg-warning::-moz-progress-value {
  @apply bg-yellow-600 rounded-full;
}

/* R: Fondo  */
.pg-danger::-webkit-progress-bar {
  @apply bg-red-500 rounded-full;
}

.pg-danger::-moz-progress-bar {
  @apply bg-red-500 rounded-full;
}

/* A: Barra */
.pg-danger::-webkit-progress-value {
  @apply bg-red-700 rounded-full;
}

.pg-danger::-moz-progress-value {
  @apply bg-red-700 rounded-full;
}

.ellipse {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (min-width: 768px) {
  .md\:w-112 {
    width: 32rem;
  }
}

@media (min-width: 1024px) {
  .lg\:h-128 {
    height: 48rem;
  }
}

.min-w-sm {
  min-width: 24rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  overflow-wrap: break-word;
}

/* purgecss end ignore */
